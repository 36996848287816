<template>
  <v-list density="compact">
    <template v-for="item in props.list" :key="item">
      <!-- 子级 存在子级的 -->
      <template v-if="item.children && item.children.length != 0">
        <v-list-item
          disabled
          :subtitle="item.name"
          @click="to(item)"
          class="cursor-pointer rounded-xl-bk"
        >
        </v-list-item>
        <template v-for="children in item.children" :key="children">
          <v-list-item
            :title="children.name"
            :icon="children.icon"
            @click="to(children)"
            class="cursor-pointer rounded-xl-bk"
            :active="children.path == $route.fullPath"
          >
            <template v-slot:prepend>
              <v-icon :icon="children.icon"></v-icon>
            </template>
          </v-list-item>
        </template>
        <!-- 子级 -->
      </template>
      <!-- 父级 -->
      <template v-else>
        <v-list-item
          :title="item.name"
          @click="to(item)"
          :active="item.path == $route.fullPath"
          class="cursor-pointer rounded-xl-bk"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>

<script setup lang="ts">
  import type { List } from './types';
  const props = defineProps<{
    list: Array<List>
  }>()

  const router = useRouter()
  const route = useRoute()
  console.debug(route.fullPath)
  const to = (item: List) => {
    if(item.path){
      router.push(item.path)
    }
  }
</script>

<style lang="scss">
  .gradient-bg {
    background: rgb(var(--v-theme-primary));
    animation: gradientAnimation 0.5s 1;
    background-size: 200% 100%;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
</style>
<template>
  <v-app id="app">
    <div>
      <!-- 侧边栏 -->
      <panel-navbar/>
      <!-- 头部 -->
      <panel-header/>
      <!-- 主体 -->
      <v-main>
        <div :class="`${website.panel_drawer ? 'ml-4' :''}`">
          <div class="w-full px-4 py-0">
            <slot />
          </div>
        </div>
      </v-main>
    </div>
  </v-app>

</template>

<script setup lang="ts">
  const website = useWebsiteStore()
  

</script>

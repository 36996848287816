<template>
  <v-menu
    v-model="ring"
    :close-on-content-click="false"
    location="bottom"
    >
    <template v-slot:activator="{ props }">
      <v-btn icon :elevation="3" size="small" class="m-2" v-bind="props">
        <v-icon >mdi-bell-ring</v-icon>
        <v-icon class="animate-ping" style="position: absolute;">mdi-bell-ring</v-icon>
      </v-btn>
    </template>
    <v-card max-width="700" max-height="500" class="rounded-xl-bk py-2" :elevation="3">
      <div
        v-for="(item, i) in 1"
        :key="i"
        class="px-4 py-2"
      >
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-alert
              v-bind="props"
              :class="`rounded-xl-bk ${isHovering ? 'opacity-100' : 'opacity-80'}`"
              text="Lorem ipsum doloue cupiditate minima, at placeat totam, magni doloremque veniam neque porro libero rerum unde voluptatem!"
              icon="mdi-information-variant-circle-outline"
              :elevation="isHovering ? 5 : 3"
            ></v-alert>
          </template>
        </v-hover>
      </div>
    </v-card>
    </v-menu>
</template>

<script setup lang="ts" >
import { ref } from 'vue'

  const ring = ref(false)
</script>

